import React from "react"
import SimpleReactLightbox from "simple-react-lightbox"

import Components from "components/components.js"

import Layout from "layout"
// import Header from '../components/Header'
// import Footer from '../components/Footer'

class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null
    }
    
    return StoryblokEntry.prepareStory(props)
  }
  
  static prepareStory(props) {
    const story = Object.assign({}, props.pageContext.story)
    story.content = JSON.parse(story.content)
    const layouts = props.pageContext.layouts

    return { story, layouts }
  }

  constructor(props) {
    super(props)

    this.state = StoryblokEntry.prepareStory(props)
  }

  render() {
    const location = this.props.location
    const content = this.state.story.content

    const pageLayout = this.state.layouts && this.state.layouts.find(({ node }) => node.uuid === content.layout)

    const layoutContent = pageLayout && JSON.parse(pageLayout.node.content)

    const contentHeader = layoutContent?.header?.[0]
    const contentFooter = layoutContent?.footer?.[0]

    return (
      <SimpleReactLightbox>
        <Layout {...{ contentHeader, contentFooter }}>
          {React.createElement(Components[content.component], {
            key: content._uid,
            blok: content,
            location,
          })}
        </Layout>
      </SimpleReactLightbox>
    )
  }
}

export default StoryblokEntry
